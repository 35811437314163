<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户名" prop="userName">
                <a-input v-model="queryParam.userName" placeholder="请输入用户名" allow-clear />
              </a-form-item>
            </a-col>
            <!-- <template v-if="advanced"> -->
            <a-col :md="8" :sm="24">
              <a-form-item label="所属驿站" prop="postStationId">
                <a-select
                  show-search
                  allowClear
                  style="width: 100%"
                  placeholder="请输入驿站模糊查找"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleQuerySearch"
                  @change="handleQueryChange"
                  v-model="queryParam.postStationId"
                >
                  <a-select-option v-for="(item, index) in stationListQuery" :key="index" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- </template> -->
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:recyclable:users:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:recyclable:users:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:recyclable:users:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:recyclable:users:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 重置密码 -->
      <reset-password ref="resetPassword" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="role" slot-scope="text, record">
          <div v-for="item in record.roles" :key="item.roleId">{{ item.roleName }}</div>
        </span>
        <a-span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </a-span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:recyclable:users:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:recyclable:users:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:recyclable:users:reset']" />
          <a @click="$refs.resetPassword.handleResetPwd(record)" v-hasPermi="['tenant:recyclable:users:reset']">
            <a-icon type="redo" />重置
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:recyclable:users:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:recyclable:users:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listUsers, delUsers, exportUsers } from '@/api/recyclable/users'
import CreateForm from './modules/CreateForm'
import ResetPassword from './modules/ResetPassword'
import debounce from 'lodash/debounce'
import { listAll } from '@/api/recyclable/postStationInfo'

export default {
  name: 'Users',
  components: {
    CreateForm,
    ResetPassword
  },
  data() {
    this.handleQuerySearch = debounce(this.handleQuerySearch, 500)

    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userName: null,
        password: null,
        tenantCode: null,
        postStationId: undefined,
        stationName: undefined,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '用户账号',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '角色',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'role' }
        },
        {
          title: '所属驿站',
          dataIndex: 'stationName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      userTypeOptions: [
        { dictLabel: '系统账号', dictValue: '00' },
        { dictLabel: '居民账号', dictValue: '01' }
      ],
      stationListQuery: []
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询用户信息列表 */
    getList() {
      this.loading = true
      listUsers(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        username: undefined,
        password: undefined,
        tenantCode: undefined,
        postStationId: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delUsers(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportUsers(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    optionsFormat(options, value) {
      return this.selectDictLabel(options, value)
    },
    listPostStationInfo(name, arrName, loadingName) {
      listAll({
        stationName: name
      }).then((res) => {
        this[loadingName] = false
        this[arrName] = res.data
      })
    },
    handleQuerySearch(value) {
      console.log('handleQuerySearch', value)
      this.qloading = true
      this.listPostStationInfo(value, 'stationListQuery', 'qloading')
    },
    handleQueryChange(value) {
      console.log('handleQueryChange', value)
      this.queryParam.stationName = value
    }
  }
}
</script>
